import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/templates/mdx-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Link to="/forms" mdxType="Link">Forms</Link>
    <h1 {...{
      "id": "sword-form"
    }}>{`Sword Form`}</h1>
    <h2 {...{
      "id": "sequence"
    }}>{`Sequence`}</h2>
    <ol>
      <li parentName="ol">{`Prepare`}</li>
      <li parentName="ol">{`Opening`}</li>
      <li parentName="ol">{`Circle the Moon with Three Rings`}</li>
      <li parentName="ol">{`Big Dipper`}</li>
      <li parentName="ol">{`Swallow Swoops Over the Water`}</li>
      <li parentName="ol">{`Block and Sweep, Right`}</li>
      <li parentName="ol">{`Block and Sweep, Left`}</li>
      <li parentName="ol">{`Little Dipper`}</li>
      <li parentName="ol">{`Swallow Returns to its Nest`}</li>
      <li parentName="ol">{`Cat Seizes the Rat with Agility`}</li>
      <li parentName="ol">{`Phoenix Lifts its Head`}</li>
      <li parentName="ol">{`Wasp Enters the Cave`}</li>
      <li parentName="ol">{`Phoenix Opens its Right Wing`}</li>
      <li parentName="ol">{`Little Dipper`}</li>
      <li parentName="ol">{`Phoenix Opens its Left Wing`}</li>
      <li parentName="ol">{`Casting the Fishing Rod`}</li>
      <li parentName="ol">{`Poking the Grass to Seek the Snake, One`}</li>
      <li parentName="ol">{`Poking the Grass to Seek the Snake, Two`}</li>
      <li parentName="ol">{`Poking the Grass to Seek the Snake, Three`}</li>
      <li parentName="ol">{`Embracing the Moon`}</li>
      <li parentName="ol">{`Birds Lodging in the Forest`}</li>
      <li parentName="ol">{`Black Dragon Sways its Tail`}</li>
      <li parentName="ol">{`Green Dragon gets out of the Water`}</li>
      <li parentName="ol">{`Lotus Swirls Around in the Wind`}</li>
      <li parentName="ol">{`Lion Shakes its Head, Left`}</li>
      <li parentName="ol">{`Lion Shakes its Head, Right`}</li>
      <li parentName="ol">{`Tiger Covers its Head`}</li>
      <li parentName="ol">{`Mustang Jumps the Ravine`}</li>
      <li parentName="ol">{`Reining in the Stallion`}</li>
      <li parentName="ol">{`Compass Pointing South`}</li>
      <li parentName="ol">{`Dusting in the Wind, One`}</li>
      <li parentName="ol">{`Dusting in the Wind, Two`}</li>
      <li parentName="ol">{`Dusting in the Wind, Three`}</li>
      <li parentName="ol">{`Pushing the Canoe with the Current`}</li>
      <li parentName="ol">{`Comet Chases the Moon`}</li>
      <li parentName="ol">{`Heavenly Steed Brings Good News`}</li>
      <li parentName="ol">{`Lifting up the Curtain`}</li>
      <li parentName="ol">{`Left Cart Wheel`}</li>
      <li parentName="ol">{`Right Cart Wheel`}</li>
      <li parentName="ol">{`Swallow Carries Pieces of Earth in its Beak`}</li>
      <li parentName="ol">{`Roc Extends its Wings`}</li>
      <li parentName="ol">{`Fishing for the Moon at the Sea Bottom`}</li>
      <li parentName="ol">{`Naza Explores the Sea Bottom`}</li>
      <li parentName="ol">{`Rhino Watches the Moon`}</li>
      <li parentName="ol">{`Hunting the Wild Goose`}</li>
      <li parentName="ol">{`Green Dragon Shows its Claws`}</li>
      <li parentName="ol">{`Phoenix Opens Both its Wings`}</li>
      <li parentName="ol">{`Step Over and Block Up, Left`}</li>
      <li parentName="ol">{`Step Over and Block Up, Right`}</li>
      <li parentName="ol">{`Hunting the Wild Goose`}</li>
      <li parentName="ol">{`White Ape Presents the Fruit.`}</li>
      <li parentName="ol">{`Falling Flowers, One`}</li>
      <li parentName="ol">{`Falling Flowers, Two`}</li>
      <li parentName="ol">{`Falling Flowers, Three`}</li>
      <li parentName="ol">{`Falling Flowers, Four`}</li>
      <li parentName="ol">{`Falling Flowers, Five`}</li>
      <li parentName="ol">{`Fair Lady Works at Shuttles`}</li>
      <li parentName="ol">{`White Tiger Sways its Tail`}</li>
      <li parentName="ol">{`Tiger Covers its Head`}</li>
      <li parentName="ol">{`Fish Jumps Over the Dragon`}</li>
      <li parentName="ol">{`Black Dragon Twists Around the Pole, Right`}</li>
      <li parentName="ol">{`Black Dragon Twists Around the Pole, Left`}</li>
      <li parentName="ol">{`Fairy Points the Way`}</li>
      <li parentName="ol">{`An Incense for Heaven`}</li>
      <li parentName="ol">{`Plum Flowers being Swept by the Wind`}</li>
      <li parentName="ol">{`Presenting the Tablet`}</li>
      <li parentName="ol">{`Return the Sword to its Original Position`}</li>
    </ol>
    <h2 {...{
      "id": "video"
    }}>{`Video`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=uISTXzmNMPA"
      }}><iframe parentName="a" {...{
          "width": 560,
          "height": 315,
          "src": "https://www.youtube-nocookie.com/embed/uISTXzmNMPA?rel=0",
          "frameBorder": "0",
          "allow": "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
          "allowFullScreen": true
        }}></iframe></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      